<div
  [matTooltipDisabled]="!name"
  matTooltip="Assignee: {{ name }}"
  class="avatar-circle"
  [ngClass]="{
    large: large
  }"
>
  <span class="center">{{ initials }}</span>
</div>
