import { Component } from '@angular/core';

@Component({
  selector: 'app-maintenance-banner',
  templateUrl: './maintenance-banner.component.html',
  styleUrls: ['./maintenance-banner.component.scss']
})
export class MaintenanceBannerComponent {

}
