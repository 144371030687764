<button
  *ngIf="!showInput"
  mat-icon-button
  matTooltip="Search by File ID or Advanced Search"
  type="button"
  (click)="toggleInput()"
>
  <mat-icon class="icon">search</mat-icon>
</button>
<div *ngIf="showInput" class="search-container" [@inAnimation]>
  <div class="search-bar">
    <mat-form-field class="search-input" appearance="outline">
      <input
        #searchInput
        (keyup.enter)="onSearch()"
        (focus)="searchInput.select()"
        [(ngModel)]="searchText"
        matInput
        placeholder="Search by File ID"
      />
    </mat-form-field>
    <button mat-icon-button (click)="onSearch()">
      <mat-icon>search</mat-icon>
    </button>
  </div>
  <div class="advanced-search">
    <button mat-stroked-button color="primary" (click)="navigateToAdvancedSearch()">Advanced Search</button>
  </div>
</div>
