<div class="body-text">
  <h5>Welcome to ALCS</h5>
  <p>
    You don't have permissions to the system. You will receive an email shortly providing an access update. <br /><br />
    If you need to switch accounts, please log out.
  </p>
  <div>
    <button class='logout' mat-flat-button color="primary" (click)="onLogout()">Log Out</button>
  </div>
</div>
